export const environment = {
  production: true,
  API_BASE_URL: '',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/dd6d1e70-aa39-4e9f-b97d-9f41a5a3419d',
  opentelemetry: {
    enabled: false,
    tracingUrl: '',
  },
  mockGlobalHeader: false,
};
